import React, { Component } from 'react';
import Header from '../../component/header';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
//import { useAlert } from "react-alert";
import { withAlert } from 'react-alert' // HOC to show the alert message
import EConfig from '../../data/enumconfig';
import LoadingAnimation from '../../component/loadinganimation';
//import Axios from "axios";
let token = '';
class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null,
            loading: false,
            fields: {},
            errors: {},
            passwordShown: false,
            errorMsg: ""
        };
        //  this.handleClick = this.handleSubmitClick.bind(this);
    }
    componentDidMount() {
        if (!sHelpers.defaultInputValidation()) {
            setTimeout(() => {
                this.props.history.push('/home');
            }, Config.minMsgTimeout);
            return false;
        }
        sHelpers.mTop();

        token = sHelpers.secureStorage.getItem('club.token');
        if (this.props.location.state !== null) {
            if (this.props.location.state !== undefined) {
                this.setState({ errorMsg: this.props.location.state.infoMsg !== undefined ? this.props.location.state.infoMsg : "" });
            }
        }

        setTimeout(() => {
            this.setState({ errorMsg: "" });
        }, 5000);

        //  this.setState({ loading: true });
    }
    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //First Name
        if (!fields["firstname"]) {
            formIsValid = false;
            this.props.alert.error("First name cannot be empty");
            errors["firstname"] = "First name cannot be empty";
            document.getElementById('input-first-name').focus();
            return formIsValid;
        }

        //Last Name
        if (!fields["lastname"]) {
            formIsValid = false;
            this.props.alert.error("Last name cannot be empty");
            document.getElementById('input-last-name').focus();
            return formIsValid;
        }

        //Membership No
        if (!fields["membershipno"]) {
            formIsValid = false;
            this.props.alert.error("Membership number cannot be empty");
            document.getElementById('input-membership-number').focus();
            return formIsValid;
        }

        //Mobile No
        if (!fields["mobileno"]) {
            formIsValid = false;
            this.props.alert.error("Mobile number cannot be empty");
            document.getElementById('input-mobile-number').focus();
            return formIsValid;
        }

        //Mobile No
        if (!fields["displayname"]) {
            formIsValid = false;
            this.props.alert.error("Display name  cannot be empty");
            document.getElementById('input-display-name').focus();
            return formIsValid;
        }

        //Email
        if (!fields["email"]) {
            formIsValid = false;
            this.props.alert.error("email cannot be empty");
            document.getElementById('input-email').focus();
            return formIsValid;
        }

        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                this.props.alert.error("Email is not valid");
                document.getElementById('input-email').focus();
                return formIsValid;
            }
        }

        //Password
        if (!fields["password"]) {
            formIsValid = false;
            this.props.alert.error("Password  cannot be empty");
            document.getElementById('input-password').focus();
            return formIsValid;
        }

        this.setState({ errors: errors });
        return formIsValid;

    }
    handleSubmitClick(e) {
        e.preventDefault();
        this.setState({ loading: true });
        sHelpers.mTop();
        setTimeout(() => {

            if (!this.handleValidation()) {
                this.setState({ loading: false });
                return false;
            }

            let postObj = {
                MembershipNo: this.state.fields["membershipno"],
                FirstName: this.state.fields["firstname"],
                LastName: this.state.fields["lastname"],
                Email: this.state.fields["email"],
                Phone: this.state.fields["mobileno"],
                DisplayName: this.state.fields["displayname"],
                Gender: parseInt(document.getElementById('input-gender').value),
                Password: this.state.fields["password"],
                CrossPlatform: EConfig.crossPlatform.Web
            }
            //console.log(postObj);


            // Axios.post(Config.baseClubUrl + 'SaveClubMemberDetails', {
            fetch(Config.baseClubUrl + 'SaveClubMemberDetails', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                .then(res => res.json())
                .then(response => {
                    //  console.log(response);
                    if (response.errorCode === 0) {
                        this.props.alert.success(response.message);
                        setTimeout(() => {
                            this.setState({ loading: false });
                            this.props.history.push('/loginverification', { maskMobileNo: this.state.fields["mobileno"].replace(/^.{7}/g, 'xxxxxxx'), mobileNo: this.state.fields["mobileno"], password: this.state.fields["password"], membershipNo: this.state.fields["membershipno"] });
                        }, Config.averageMsgTimeout);
                    }
                    else {
                        this.setState({ loading: false });
                        this.props.alert.error(response.message);
                    }
                })
                .catch(err => {
                    //  console.log(err);
                    this.setState({ loading: false });
                    this.props.alert.error(err);
                });

        }, Config.minMsgTimeout);

    }
    handleChange(field, e) {
        let fields = this.state.fields;
        // console.log(fields);
        // console.log(e.target.value);
        fields[field] = e.target.value;
        this.setState({ fields });
    }
    togglePassword(value) {
        this.setState({ passwordShown: value });
    }
    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                <Header></Header>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="wrapper-content bg-white">
                                <div className="section-scroll" id="section-apply">
                                    <div className="bg-light p-5">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="section-title mb60 text-center">
                                                    <h1>Register</h1>
                                                    <div>Register with membership number online, All you need to do is provide your details below application form.</div>
                                                    <div style={{ fontSize: "11px", color: "#dc3545", padding: "6px" }}>{this.state.errorMsg}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <form className="" onSubmit={this.handleSubmitClick.bind(this)}>
                                                    <fieldset>
                                                        <div className="row">
                                                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 form-group ">
                                                                <label className="control-label" htmlFor="first-name">First Name</label>
                                                                <div className="">
                                                                    <input id="input-first-name" name="input-first-name" type="text" ref="firstnameInput" placeholder="First Name" className="form-control input-md" maxLength="30" onChange={this.handleChange.bind(this, "firstname")} value={this.state.fields["firstname"]} />
                                                                    <span className="help-block"> </span> </div>
                                                            </div>
                                                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 form-group ">
                                                                <label className="control-label" htmlFor="last-name">Last Name</label>
                                                                <div className="">
                                                                    <input id="input-last-name" name="input-last-name" type="text" ref="lastnameInput" placeholder="Last Name" className="form-control input-md" maxLength="30" onChange={this.handleChange.bind(this, "lastname")} value={this.state.fields["lastname"]} />
                                                                    <span className="help-block"> </span> </div>
                                                            </div>
                                                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 form-group ">
                                                                <label className="control-label" htmlFor="membership-number">Membership Number</label>
                                                                <div className="">
                                                                    <input id="input-membership-number" name="input-membership-number" type="text" placeholder="Membership Number" className="form-control input-md" maxLength="50" onChange={this.handleChange.bind(this, "membershipno")} value={this.state.fields["membershipno"]} />
                                                                    <span className="help-block"> </span> </div>
                                                            </div>
                                                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 form-group ">
                                                                <label className="control-label" htmlFor="mobile-number">Mobile Number</label>
                                                                <div className="">
                                                                    <input id="input-mobile-number" name="input-mobile-number" type="text" placeholder="Mobile Number" className="form-control input-md" maxLength="15" pattern="[0-9]*" onChange={this.handleChange.bind(this, "mobileno")} value={this.state.fields["mobileno"]} />
                                                                    <span className="help-block"> </span> </div>
                                                            </div>
                                                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 form-group ">
                                                                <label className="control-label" htmlFor="gender">Gender</label>
                                                                <div className="">
                                                                    <select id="input-gender" name="input-gender" className="form-control input-md" onChange={this.handleChange.bind(this, "gender")}>
                                                                        <option value="1">Male</option>
                                                                        <option value="2">Female</option>
                                                                    </select>
                                                                    <span className="help-block"> </span> </div>
                                                            </div>
                                                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 form-group ">
                                                                <label className=" control-label" htmlFor="display-name">Display Name</label>
                                                                <div className="">
                                                                    <input id="input-display-name" name="input-display-name" type="text" placeholder="Display Name" className="form-control input-md" maxLength="30" onChange={this.handleChange.bind(this, "displayname")} value={this.state.fields["displayname"]} />
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 form-group ">
                                                                <label className=" control-label" htmlFor="email">E-Mail</label>
                                                                <div className="">
                                                                    <input id="input-email" name="input-email" type="text" placeholder="E-mail" className="form-control input-md" maxLength="100" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]} />
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 form-group ">
                                                                <label className="control-label" htmlFor="password">Password</label>
                                                                <div className="">
                                                                    <input id="input-password" name="input-password" type={this.state.passwordShown ? "text" : "password"} placeholder="Password" className="form-control" maxLength="50" onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]} />
                                                                </div>
                                                                <div style={{ float: "right", marginTop: "-46px", marginRight: "12px" }} >
                                                                    {this.state.passwordShown ? <i className="fa fa-eye-slash" onClick={this.togglePassword.bind(this, false)}></i> : <i className="fa fa-eye" onClick={this.togglePassword.bind(this, true)}></i>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                                                <div className="">
                                                                    <button id="input-register" name="input-register" className="btn btn-primary btn-block btn-sm  btn-cusm btn-bg">Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withAlert()(Register);
