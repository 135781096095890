import React, { Component } from 'react';
import Header from '../../component/header';
import { Zoom } from 'react-slideshow-image';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
import 'react-slideshow-image/dist/styles.css';
import './home.scss';
import { withAlert } from 'react-alert' // HOC to show the alert message
//import sHelpers from '../../modules/helpers/statichelpers';
// const slideImages = [
//     '../t1/assets/images/slider/slider-1.jpg',
//     '../t1/assets/images/slider/slider-2.jpg',
//     '../t1/assets/images/slider/slider-3.jpg',
//     '../t1/assets/images/slider/slider-4.jpg',
//     '../t1/assets/images/slider/slider-5.jpg',
// ];
const zoomInProperties = {
    indicators: true,
    scale: 1.4
}
let token = '';
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultsliderImages: [
                {
                    imageUrl: "../t1/assets/images/slider/club-lucid-no-image.jpg",
                }
            ],
            sliderImages: [],
            pageHeading: "Club",
            pageDescription: "",
        }
    }

    componentDidMount() {
        if (!sHelpers.defaultInputValidation()) {
            setTimeout(() => {
                this.props.history.push('/pageerror');
            }, Config.minMsgTimeout);
            return false;
        }

        token = sHelpers.secureStorage.getItem('club.token');
        /// Get Content Data


        sHelpers.mTop();
        this.getMenuList();
        // const script = document.createElement("script");
        // script.src = "../t1/assets/js/owl.carousel.min.js";
        // script.async = true;
        // document.body.appendChild(script);
        // setTimeout(() => {
        //     this.props.history.push('/register');
        // }, 1200);
    }

    getMenuList() {

        fetch(Config.baseClubUrl + 'GetClubMenuList', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                // console.log(response);
                if (response.errorCode === 0) {
                    // Get Home Page Detail
                    // home page type is 2
                    let pageIds = [];
                    let menus = response.menus;
                    sHelpers.secureStorage.setItem('club.menus', response.menus);

                    if (menus.length > 0) pageIds = menus.filter(m => m.menuType === Config.pageType.home); // Home page Type                  
                    if (pageIds.length > 0) {

                        fetch(Config.baseClubUrl + 'GetClubPageDetail?PageId=' + pageIds[0].menuId + '&PageType=' + Config.pageType.home, {
                            method: 'GET',
                            headers: {
                                Authorization: "Bearer " + token,
                                'content-type': 'application/json'
                            },
                        })
                            .then(res => res.json())
                            .then(response => {
                                // console.log(response);
                                if (response.errorCode === 0) {
                                    //Get
                                    this.setState({ sliderImages: response.pageDetail.imageList, pageHeading: response.pageDetail.pageTitle, pageDescription: response.pageDetail.preview });
                                    // console.log(this.state.sliderImages);
                                }
                                else {
                                    this.props.alert.error(response.message);
                                }
                            })
                            .catch(err => {
                                this.props.alert.error(response.message);
                            });

                    }


                    // Get News List
                    fetch(Config.baseClubUrl + 'GetClubNewsPageList', {
                        method: 'GET',
                        headers: {
                            Authorization: "Bearer " + token,
                            'content-type': 'application/json'
                        },
                    })
                        .then(res => res.json())
                        .then(response => {
                            //console.log(response);
                            if (response.errorCode === 0) {
                                sHelpers.secureStorage.setItem('club.news', response.news);
                            }
                            else {
                                this.props.alert.error(response.message);
                            }
                        })
                        .catch(err => {
                            this.props.alert.error(err);
                        });


                }
                else {
                    this.props.alert.error(response.message);
                }
            })
            .catch(err => {
                this.props.alert.error(err);
            });
    }

    render() {

        return (

            <>
                {/* <!-- /.top-bar --> */}
                <Header></Header>
                {/* <Slider  {...homeslidersettings} >
                    <img src="../t1/assets/images/slider/slide-1.png" alt="The Last of us" />
                    <img src="../t1/assets/images/slider/slide-1.png" alt="The Last of us" />
                </Slider> */}
                {/* <!-- /.navigation start--> */}
                {/* <!-- /.search start--> */}

                <div className="slide-container">
                    {this.state.sliderImages.length > 0 &&
                        <Zoom {...zoomInProperties}>
                            {this.state.sliderImages.map((each, index) => (
                                <div key={index} style={{ width: "100%" }}>
                                    <img style={{ objectFit: "cover", width: "100%" }} src={Config.imgBaseURL + each.pathName + each.imageName} alt="club-app-lucid" />
                                </div>
                            ))}
                        </Zoom>
                    }
                    {this.state.sliderImages.length === 0 &&
                        <Zoom {...zoomInProperties}>
                            {this.state.defaultsliderImages.map((each, index) => (
                                <div key={index} style={{ width: "100%" }}>
                                    <img style={{ objectFit: "cover", width: "100%" }} src={each.imageUrl} alt="club-app-lucid" />
                                </div>
                            ))}
                        </Zoom>
                    }
                </div>

                {/* <div>
                    <Slide easing="ease">
                        <div className="each-slide">
                            <div style={{ 'backgroundImage': `url(${slideImages[0]})` }}>
                                <span>Slide 1</span>
                            </div>
                        </div>
                        <div className="each-slide">
                            <div style={{ 'backgroundImage': `url(${slideImages[1]})` }}>
                                <span>Slide 2</span>
                            </div>
                        </div>
                        <div className="each-slide">
                            <div style={{ 'backgroundImage': `url(${slideImages[2]})` }}>
                                <span>Slide 3</span>
                            </div>
                        </div>
                    </Slide>
                </div> */}
                <div className="section-space20">
                    <div className="container">
                        <div className="row">
                            <div className="offset-xl-2 col-xl-8 offset-md-2 col-md-8 offset-md-2 col-md-8 col-sm-12 col-12">
                                <div className="mb60 text-center section-title">
                                    {/* <!-- section title start--> */}
                                    <h1>Welcome to {this.state.pageHeading}</h1>
                                    {this.state.pagePreview !== "" &&
                                        <p className="home-content" dangerouslySetInnerHTML={{ __html: this.state.pageDescription }}></p>
                                        // <p>The late Maharaja Nalvadi Krishnaraja Wadiyar,
                                        // with far-sightedness and his profound sense of scenic beauty,
                                        // chose the foothills of the famous chamundi hills as the locale
                                        //      for the race and golf courses. <strong>The JWGC is located on 110 acres</strong>
                                        //       in the premises of the mysore race club. On 1940 on his accession
                                        //       to the throne, his highness Jayachamaraja Wadiyar took a keen interest
                                        //        in racing and golf and maintained the golf course.
                                        // </p>
                                    }
                                </div>
                                {/* <!-- /.section title start--> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withAlert()(Home);