import React, { Component } from 'react';
import './splashscreen.scss';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
import * as QueryString from "query-string";
export default class Home extends Component {

    componentDidMount() {
        // let loggedToken = localStorage.getItem('club.token');
        // let loggedInOrg = sHelpers.secureStorage.getItem('club.organization.id');
        //let loggedInPropertyId = localStorage.getItem('club.property.id');
        //let loggedmobileNo = sHelpers.secureStorage.getItem('club.mobile.no');

        let inputParam = QueryString.parse(window.location.search);

        if (inputParam.ukey === undefined || inputParam.ukey === "" || inputParam.ukey === null) {           
            this.props.history.push('/pageerror');
            return false;            
    }

    let urlkey = inputParam.ukey
    const urlString = urlkey.replace(/ /g,'+');
    let posturlObj = {            
        username: Config.linkUserName,
        password: Config.linkPassword,
        urlKey:urlString,                                          
        requestIP: "",
        userId: "" 
    }
    // Update Contact Detail
    fetch(Config.baseAPIUrl + 'LongUrl', {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(posturlObj)
    })
        .then(res => res.json())
        .then(response => {                     
            if(response.isSuccessful) 
            {
                this.getLaunch(response);
            }               
             else{
                this.props.alert.error("URL not found. ");   
             }           

        })
        .catch(err => {
            console.log(err);    
            this.props.alert.error("URL not found. ");     
        });


        // let orgId = inputParam.orgid;
        // let orgName = inputParam.orgname;
        // let propId = inputParam.propid;
        // let propName = inputParam.propname;
        // let statsId = inputParam.statsid;
        // let userId = inputParam.userid;
        // let token = "";

        // if (orgId === undefined || orgId === "" || orgId === null) {
        //     this.props.history.push('/pageerror');
        //     return false;
        // }
        // if (propId === undefined || propId === "" || propId === null) {
        //     this.props.history.push('/pageerror');
        //     return false;
        // }
        // if (userId === undefined || userId === "" || userId === null) {
        //     this.props.history.push('/pageerror');
        //     return false;
        // }

        // if (statsId === undefined || statsId === "" || statsId === null) {
        //     statsId = "0";
        // }


        // // Get Token
        // let urlParam = 'PropertyId=' + propId + '&UserId=' + userId;
        // fetch(Config.baseClubUrl + 'GenerateToken?' + urlParam, {
        //     method: 'POST',
        // })
        //     .then(res => res.json())
        //     .then(response => {
        //         //   console.log(response);
        //         if (response.errorCode === 0) {
        //             token = response.token;

        //             if (token === undefined || token === "" || token === null) {
        //                 this.props.history.push('/pageerror');
        //                 return false;
        //             }

        //             sHelpers.secureStorage.setItem('club.token', response.token);
        //             sHelpers.secureStorage.setItem('club.organization.id', orgId);
        //             sHelpers.secureStorage.setItem('club.organization.name', orgName);
        //             sHelpers.secureStorage.setItem('club.property.id', propId);
        //             sHelpers.secureStorage.setItem('club.property.name', propName);
        //             sHelpers.secureStorage.setItem('club.userid', userId);


        //             let urlParam = 'ModuleCode=LOGO';
        //             fetch(Config.baseClubUrl + 'GetClubAppSetting?' + urlParam, {
        //                 method: 'GET',
        //                 headers: {
        //                     Authorization: "Bearer " + response.token,
        //                     'content-type': 'application/json'
        //                 },
        //             })
        //                 .then(res => res.json())
        //                 .then(response => {
        //                     //  console.log(response);
        //                     if (response.errorCode === 0) {
        //                         if (response.appSettingList.length > 0) {
        //                             sHelpers.secureStorage.setItem('club.logo', response.appSettingList[0].keyValue);
        //                         }
        //                     }
        //                 })
        //                 .catch(err => {
        //                     console.log(err);
        //                     //  this.props.alert.error(err);
        //                 });

        //             // let exloggedout = false

        //             // if (orgId !== loggedInOrg) {
        //             //     exloggedout = true;
        //             // }

        //             // if (propId !== loggedInPropertyId) {
        //             //     exloggedout = true;
        //             // }

        //             // if (token !== loggedToken) {
        //             //     exloggedout = true;
        //             // }


        //             // 
        //             this.getMenuList();


        //         }
        //         else {
        //             setTimeout(() => {
        //                 this.props.history.push('/pageerror');
        //             }, Config.averageMsgTimeout);
        //         }
        //     })
        //     .catch(err => {
        //         console.log(err);
        //         //  this.props.alert.error(err);
        //     });




    }

    getLaunch(response){
    
        const longUrlInput = response.longUrlInput;
        
        let orgId = longUrlInput.organizationId;
        let orgName = longUrlInput.organizationName;
        let propId = longUrlInput.propertyId;
        let propName = longUrlInput.propertyName;
        let statsId = longUrlInput.statsid;
        let userId = longUrlInput.userId;
        let token = "";

        if (orgId === undefined || orgId === "" || orgId === null) {
            this.props.history.push('/pageerror');
            return false;
        }
        if (propId === undefined || propId === "" || propId === null) {
            this.props.history.push('/pageerror');
            return false;
        }
        if (userId === undefined || userId === "" || userId === null) {
            this.props.history.push('/pageerror');
            return false;
        }

        if (statsId === undefined || statsId === "" || statsId === null) {
            statsId = "0";
        }


        // Get Token
        let urlParam = 'PropertyId=' + propId + '&UserId=' + userId;
        fetch(Config.baseClubUrl + 'GenerateToken?' + urlParam, {
            method: 'POST',
        })
            .then(res => res.json())
            .then(response => {
                //   console.log(response);
                if (response.errorCode === 0) {
                    token = response.token;

                    if (token === undefined || token === "" || token === null) {
                        this.props.history.push('/pageerror');
                        return false;
                    }

                    sHelpers.secureStorage.setItem('club.token', response.token);
                    sHelpers.secureStorage.setItem('club.organization.id', orgId);
                    sHelpers.secureStorage.setItem('club.organization.name', orgName);
                    sHelpers.secureStorage.setItem('club.property.id', propId);
                    sHelpers.secureStorage.setItem('club.property.name', propName);
                    sHelpers.secureStorage.setItem('club.userid', userId);


                    let urlParam = 'ModuleCode=LOGO';
                    fetch(Config.baseClubUrl + 'GetClubAppSetting?' + urlParam, {
                        method: 'GET',
                        headers: {
                            Authorization: "Bearer " + response.token,
                            'content-type': 'application/json'
                        },
                    })
                        .then(res => res.json())
                        .then(response => {
                            //  console.log(response);
                            if (response.errorCode === 0) {
                                if (response.appSettingList.length > 0) {
                                    sHelpers.secureStorage.setItem('club.logo', response.appSettingList[0].keyValue);
                                }
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            //  this.props.alert.error(err);
                        });

                    // let exloggedout = false

                    // if (orgId !== loggedInOrg) {
                    //     exloggedout = true;
                    // }

                    // if (propId !== loggedInPropertyId) {
                    //     exloggedout = true;
                    // }

                    // if (token !== loggedToken) {
                    //     exloggedout = true;
                    // }


                    // 
                    this.getMenuList();


                }
                else {
                    setTimeout(() => {
                        this.props.history.push('/pageerror');
                    }, Config.averageMsgTimeout);
                }
            })
            .catch(err => {
                console.log(err);
                //  this.props.alert.error(err);
            });


        
    }


    getMenuList() {

        fetch(Config.baseClubUrl + 'GetClubMenuList', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + sHelpers.secureStorage.getItem('club.token'),
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                //  console.log(response);
                if (response.errorCode === 0) {
                    sHelpers.secureStorage.setItem('club.menus', response.menus);

                }
                else {
                    this.props.alert.error(response.message);
                    sHelpers.secureStorage.setItem('club.menus', []);
                }


                // Get News List
                fetch(Config.baseClubUrl + 'GetClubNewsPageList', {
                    method: 'GET',
                    headers: {
                        Authorization: "Bearer " + sHelpers.secureStorage.getItem('club.token'),
                        'content-type': 'application/json'
                    },
                })
                    .then(res => res.json())
                    .then(response => {
                        //   console.log(response);
                        if (response.errorCode === 0) {
                            sHelpers.secureStorage.setItem('club.news', response.news);
                        }
                        else {
                            this.props.alert.error(response.message);
                            sHelpers.secureStorage.setItem('club.news', response.news);
                        }

                    })
                    .catch(err => {
                        this.props.alert.error(err);
                    });


                setTimeout(() => {
                    this.props.history.push('/home');
                }, 5000);
            })
            .catch(err => {
                this.props.alert.error(err);
            });
    }

    render() {
        return (
            <>
                <div className="splash">
                    <div className="splash_logo">
                        <img src="../t1/assets/images/lucid-logo.png" alt="lucid-club-erp-bangalore"></img>
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>

                    {/* <Loader type="BallTriangle" color="#00BFFF" height={80} width={80} /> */}

                    <div className="splash_svg">
                        <svg width="100%" height="100%">
                            <rect width="100%" height="100%" />
                        </svg>
                    </div>
                    <div className="splash_minimize">
                        <svg width="100%" height="100%">
                            <rect width="100%" height="100%" />
                        </svg>
                    </div>

                </div>
                {/* <div className="text">
                    <p>Duis quis</p>
                    <p>nec sapien</p>
                    <button>More</button>
                </div> */}
            </>
        )
    }
}
