import React, { Component } from 'react';
import Header from '../../component/header';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
import 'react-slideshow-image/dist/styles.css';
import './member.scss';
import { withAlert } from 'react-alert' // HOC to show the alert message
import * as QueryString from "query-string";


let token = '';
let inputParam = '';
class Member extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageHeading: "Member",
            pageDescription: "",
            menuList: sHelpers.secureStorage.getItem('club.menus'),
            pageId: 0,
            pageName: "",
            memberList: []
        }
    }

    componentDidMount() {
        if (!sHelpers.defaultInputValidation()) {
            setTimeout(() => {
                this.props.history.push('/pageerror');
            }, Config.minMsgTimeout);
            return false;
        }

        token = sHelpers.secureStorage.getItem('club.token');
        /// Get Content Data

        inputParam = QueryString.parse(window.location.search);

        this.setState({ pageId: parseInt(inputParam.pid) })

        if (inputParam.pid === undefined || inputParam.pid === "" || inputParam.pid === null) {
            this.props.history.push('/home');
            return false;
        }


        sHelpers.mTop();
        this.getPageDetails();
    }



    getPageDetails() {

        this.setState({ loading: true });
        fetch(Config.baseClubUrl + 'GetClubPageDetail?PageId=' + inputParam.pid + '&PageType=' + Config.pageType.member, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                console.log(response);
                if (response.errorCode === 0) {
                    //Get
                    this.setState({ memberList: response.pageDetail.memberList, pageHeading: response.pageDetail.pageTitle, pageDescription: response.pageDetail.preview, pageName: response.pageDetail.pageName });
                    // console.log(this.state.sliderImages);
                }
                else {
                    this.props.alert.error(response.message);
                }
                this.setState({ loading: false });
            })
            .catch(err => {
                this.setState({ loading: false });
                this.props.alert.error(err);
            });
    }

    render() {

        return (

            <>
                {/* <!-- /.top-bar --> */}
                <Header></Header>


                <div className="section-space20">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-md-12 col-md-12 col-sm-12 col-12">
                                <div className="mb60 text-center section-title">
                                    <h1>{this.state.pageName}</h1>
                                </div>
                                <div className="mb60 section-title">

                                    <div class="col-md-12">
                                        <div class="wrapper-content bg-white p-3 p-lg-5">
                                            <div class="row">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <div class="mb60">
                                                        <p class="lead" dangerouslySetInnerHTML={{ __html: this.state.pageDescription }}></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                {
                                                    this.state.memberList.map((each, index) => (
                                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                                            <div class="team-block mb-5">
                                                                <div class="team-img mb-3"> <img src={Config.imgBaseURL + each.imagePathName + each.imageName} alt="club-member" class="img-fluid member-img" /> </div>
                                                                <div class="team-content text-center">
                                                                    <h3 class="mb-0">{each.memberName}</h3>
                                                                    <small class="designation">{each.designation}</small>
                                                                    <p class="email">{each.emialId}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>









                                </div>

                                {/* <!-- /.section title start--> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withAlert()(Member);