import React, { Component } from 'react';
import Header from '../../component/header';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
import LoadingAnimation from '../../component/loadinganimation';
import { withAlert } from 'react-alert' //  to show the alert message
import EConfig from '../../data/enumconfig';
import './forgotpassword.scss';
import OtpInput from 'react-otp-input';
let token = '';

class ConfirmPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            password: '',
            loading: false,
            fields: {},
            newPasswordShown: false,
            confirmPasswordShown: false,
            otp: '',
            otpstyle: {
                width: "3em",
                fontSize: "14px",
                height: "52px",
                padding: "0px",
                color: "#555",
                backgroundImage: "none",
                backgroundColor: "#fff",
                borderRadius: "4px",
                boxShadow: "inset 0 0px 0px rgb(0 0 0 / 8%)",
                marginBottom: "10px",
                border: "2px solid #e6ecef"
            }
        }
    }


    componentDidMount() {


        if (!sHelpers.defaultInputValidation()) {
            setTimeout(() => {
                this.props.history.push('/home');
            }, Config.minMsgTimeout);
            return false;
        }
        token = sHelpers.secureStorage.getItem('club.token');
        sHelpers.mTop();
    }

    componentDidCatch(error, errorInfo) {
        // console.log(error);
        // console.log(errorInfo);
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }
    handleContinueClick(e) {
        e.preventDefault();
        this.setState({ loading: true });
        sHelpers.mTop();

        setTimeout(() => {

            let fields = this.state.fields;

            // console.log(sHelpers.secureStorage.getItem('club.membership.no'));
            // console.log(sHelpers.secureStorage.getItem('club.mobile.no'));

            if (!fields["newpassword"]) {
                this.props.alert.error("New Password cannot be empty");
                document.getElementById('input-new-password').focus();
                this.setState({ loading: false });
                return false;
            }
            if (!fields["confirmpassword"]) {
                this.props.alert.error("Confirm Password cannot be empty");
                document.getElementById('input-confirm-password').focus();
                this.setState({ loading: false });
                return false;
            }

            if (this.state.fields["newpassword"] !== this.state.fields["confirmpassword"]) {
                this.props.alert.error("Please make sure your passwords match.");
                document.getElementById('input-confirm-password').focus();
                this.setState({ loading: false });
                return false;
            }

            if (this.state.otp === "") {
                this.props.alert.error("OTP Password cannot be empty");
                this.setState({ loading: false });
                return false;
            }

            let postObj = {
                MembershipNo: sHelpers.secureStorage.getItem('club.membership.no'),
                Phone: sHelpers.secureStorage.getItem('club.mobile.no'),
                OTP: this.state.otp,
                Password: this.state.fields["confirmpassword"],
                CrossPlatform: EConfig.crossPlatform.Web
            }
            //  console.log(postObj);


            fetch(Config.baseClubUrl + 'ValidateClubMemberForgotPassword', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                .then(res => res.json())
                .then(response => {
                    //console.log(response);
                    if (response.errorCode === 0) {
                        this.props.alert.success(response.message);
                        setTimeout(() => {
                            this.setState({ loading: false });
                            this.props.history.push('/login');
                        }, Config.maxMsgTimeout);
                    }
                    else {
                        this.setState({ loading: false });
                        this.props.alert.error(response.message);
                    }
                })
                .catch(err => {
                    //  console.log(err);
                    this.setState({ loading: false });
                    this.props.alert.error(err);
                });
        }, Config.minMsgTimeout);
    }
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    togglePassword(type, value) {
        if (type === 1)
            this.setState({ newPasswordShown: value })
        if (type === 2)
            this.setState({ confirmPasswordShown: value })
    }

    handleChangeOTP = (otp) => this.setState({ otp });


    render() {
        console.log(this.state.otp);
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                <Header></Header>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="wrapper-content bg-white">
                                <div className="section-scroll" id="section-apply">
                                    <div className="bg-light p-5">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="section-title mb60 text-center">
                                                    <h1>Reset Password</h1>
                                                    <p>Please create a new password.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="offset-xl-4 col-xl-8 offset-md-2 col-md-12 offset-md-2 col-md-12 col-sm-12 col-12">
                                                <form className="" onSubmit={this.handleContinueClick.bind(this)}>
                                                    <fieldset>
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12 form-group ">
                                                                <label className="control-label" htmlFor="input-new-password">New Password</label>
                                                                <div className="">
                                                                    <input id="input-new-password" name="input-new-password" type={this.state.newPasswordShown ? "text" : "password"} placeholder="Create new password" className="form-control input-md" maxLength="50" onChange={this.handleChange.bind(this, "newpassword")} value={this.state.fields["newpassword"]} autoFocus />
                                                                </div>
                                                                <div style={{ float: "right", marginTop: "-46px", marginRight: "12px" }} >
                                                                    {this.state.newPasswordShown ? <i className="fa fa-eye-slash" onClick={this.togglePassword.bind(this, 1, false)}></i> : <i className="fa fa-eye" onClick={this.togglePassword.bind(this, 1, true)}></i>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 form-group">
                                                                <label className="control-label" htmlFor="input-new-password">Confirm Password</label>
                                                                <div className="">
                                                                    <input id="input-confirm-password" name="input-confirm-password" type={this.state.confirmPasswordShown ? "text" : "password"} placeholder="Confirm your password" className="form-control" maxLength="50" onChange={this.handleChange.bind(this, "confirmpassword")} value={this.state.fields["confirmpassword"]} />
                                                                </div>
                                                                <div style={{ float: "right", marginTop: "-46px", marginRight: "12px" }} >
                                                                    {this.state.confirmPasswordShown ? <i className="fa fa-eye-slash" onClick={this.togglePassword.bind(this, 2, false)}></i> : <i className="fa fa-eye" onClick={this.togglePassword.bind(this, 2, true)}></i>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 form-group">
                                                                <label className="control-label" htmlFor="input-new-password">OTP</label>
                                                                <OtpInput
                                                                    value={this.state.otp}
                                                                    onChange={this.handleChangeOTP}
                                                                    numInputs={4}
                                                                    separator={<span>-</span>}
                                                                    inputStyle={this.state.otpstyle}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="form-group col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12">
                                                                <div className="">
                                                                    <button id="input-change" name="input-change" className="btn btn-primary btn-block btn-cusm btn-sm btn-bg">Change</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </fieldset>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withAlert()(ConfirmPassword);