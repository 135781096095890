//import logo from './logo.svg';
//import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";// JK Custom
import Home from './view/home';
import Register from './view/register';
import Login from './view/login';
import LoginVerification from './view/verification/loginverification';
import Dashboard from './view/dashboard';
import Logout from './view/logout';
import PaymentProcess from './view/payment';
import PaymentVerification from './view/payment/paymentverification';
import PaymentFailed from './view/payment/paymentfailed';
import PaymentSuccess from './view/payment/paymentsuccess';
import SplashScreen from './view/splashscreen';
import PageError from './view/error/pageerror';
import ForgotPassword from './view/forgotpassword';
import ConfirmPassword from './view/forgotpassword/confirmpassword';

// Page
import PageNormal from './view/page'; // normal 
import PageGallery from './view/page/gallery'; // gallery 
import PageList from './view/page/list'; // list page 
import PageContact from './view/page/contact'; // contact
import PageMember from './view/page/member'; // member

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route component={Home} exact path="/home" ></Route>
          <Route component={SplashScreen} exact path="/" ></Route>
          <Route key="register" component={Register} path="/register" exact></Route>
          <Route key="login" component={Login} path="/login" exact></Route>
          <Route key="loginverification" component={LoginVerification} path="/loginverification" exact></Route>
          <Route key="dashboard" component={Dashboard} path="/dashboard" exact></Route>
          <Route key="logout" component={Logout} path="/logout" exact></Route>
          <Route key="paymentprocess" component={PaymentProcess} path="/paymentprocess" exact></Route>
          <Route key="paymentverification" component={PaymentVerification} path="/paymentverification" exact></Route>
          <Route key="paymentfailed" component={PaymentFailed} path="/paymentfailed" exact></Route>
          <Route key="paymentsuccess" component={PaymentSuccess} path="/paymentsuccess" exact></Route>
          <Route key="pageerror" component={PageError} path="/pageerror" exact></Route>
          <Route key="forgotpassword" component={ForgotPassword} path="/forgotpassword" exact></Route>
          <Route key="confirmpassword" component={ConfirmPassword} path="/confirmpassword" exact></Route>
          <Route key="page" component={PageNormal} path="/page" exact></Route>
          <Route key="gallery" component={PageGallery} path="/gallery" exact></Route>
          <Route key="list" component={PageList} path="/list" exact></Route>
          <Route key="contact" component={PageContact} path="/contact" exact></Route>
          <Route key="member" component={PageMember} path="/member" exact></Route>
          <Redirect to='/' />
        </Switch>
      </Router>
      {/* <Header></Header> */}
      {/* <Slider></Slider> */}
    </div>
  );
}

export default App;
