import React, { Component } from 'react';
import Header from '../../component/header';
export default class PaymentFailed extends Component {
    render() {
        return (
            <>
                <Header></Header>

                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="wrapper-content bg-white pinside60">
                                <div class="row">
                                    <div class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div class="error-ctn text-center">
                                            <h1 class="error-title mb40">Transaction Failed.</h1>
                                            <a href="/dashboard" class="btn btn-secondary text-center">Please Try Again</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}