import React, { Component } from 'react';
import Header from '../../component/header';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
import LoadingAnimation from '../../component/loadinganimation';
import { withAlert } from 'react-alert' // HOC to show the alert message
import EConfig from '../../data/enumconfig';
let paymentInitiatedId = '';
//import moment from "moment";
let token = '';
class PaymentVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            outstandingBalance: 0.00,
            otherAmount: 0,
            payNowStatus: "disabled",
            redirectUrl: '',
            paymentRequestCode: '',
            paymentRequestKey: ''
        }
    }

    componentDidMount() {

        paymentInitiatedId = sHelpers.secureStorage.getItem('club.paymentInitiate.Id');

        if (!sHelpers.defaultInputValidation()) {
            setTimeout(() => {
                this.props.history.push('/home');
            }, Config.minMsgTimeout);
            return false;
        }

        else if (!sHelpers.LoggedinInputValidation()) {
            this.props.alert.error("Session Expired! Please log in again.");
            this.setState({ loading: true });
            setTimeout(() => {
                //this.props.history.push('/home');
            }, Config.minMsgTimeout);
            return false;
        }



        else if (paymentInitiatedId === null || paymentInitiatedId === "") {
            this.props.alert.error("Transaction failed. Please try again.");
            this.setState({ loading: true });
            setTimeout(() => {
                sHelpers.secureStorage.removeItem('club.paymentInitiate.Id');
                this.props.history.push('/dashboard');
            }, Config.minMsgTimeout);
            return false;
        }

        token = sHelpers.secureStorage.getItem('club.token');
        this.getPaymentStatus();
    }

    gotoInitiate(msg) {
        this.props.alert.error(msg);
        sHelpers.secureStorage.removeItem('club.paymentInitiate.Id');
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/dashboard');
        }, Config.minMsgTimeout);
    }

    getPaymentStatus() {
        this.setState({ loading: true });
        setTimeout(() => {
            let postObj = {
                MembershipId: parseInt(sHelpers.secureStorage.getItem('club.membership.id')),
                MemberName: sHelpers.secureStorage.getItem("club.displayname"),
                MembershipNo: sHelpers.secureStorage.getItem("club.membership.no"),
                PaymentInitiateId: paymentInitiatedId,
                Phone: sHelpers.secureStorage.getItem("club.mobile.no"),
                DeviceId: 0,
                CrossPlatform: EConfig.crossPlatform.Web
            }
            //  console.log(postObj);

            fetch(Config.baseClubUrl + 'GetPaymentStatus', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                .then(res => res.json())
                .then(response => {
                    //   console.log(response);
                    //  console.log(EConfig.paymentStatus.Success);
                    if (response.errorCode === 0) {
                        if (response.status === EConfig.paymentStatus.Success) {
                            sHelpers.secureStorage.removeItem('club.paymentInitiate.Id');
                            setTimeout(() => {
                                this.props.history.push('/paymentsuccess')
                            }, Config.minMsgTimeout);
                        }
                        else if (response.status === EConfig.paymentStatus.Pending) {
                            setTimeout(() => {
                                this.getPaymentStatus();
                            }, Config.verificationTimeout);
                        }
                        else if (response.status === EConfig.paymentStatus.Failure) {
                            this.gotoInitiate(response.statusMessage);
                        }
                        else if (response.status === EConfig.paymentStatus.Cancelled) {
                            this.gotoInitiate(response.statusMessage);
                            sHelpers.secureStorage.removeItem('club.paymentInitiate.Id');
                            setTimeout(() => {
                                this.props.history.push('/paymentfailed')
                            }, Config.minMsgTimeout);
                        }
                    }
                    else {
                        this.gotoInitiate("Transaction failed. Please try again");
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.gotoInitiate("Transaction failed. Please try again");
                });
        }, Config.minMsgTimeout);
    }
    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                <Header></Header>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="wrapper-content bg-white p-3 p-lg-5">
                                <div class="loan-eligibility-block">
                                    <div class="row">
                                        <div class="col-xl-12 col-md-12 col-md-12 col-sm-12 col-12">
                                            <div class="error-ctn text-center">
                                                <h1 class="error-title mb40">Please Wait...</h1>
                                                <p><strong>We're Processing your payment.</strong></p>
                                                <p>This process may take a few seconds, So please do not click the back button or refresh in you browser.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <form id="hdfc-submit" method="POST" action={this.state.redirectUrl} onSubmit={this.handleSubmit}>
                        <input type="hidden" name="encRequest" value={this.state.paymentRequestKey} />
                        <input type="hidden" name="access_code" value={this.state.paymentRequestCode} />
                        <button style={{ display: "none" }} ref={input => this.inputElement = input}>Submit</button>
                        {/* <button style={{ display: "none" }} ref={(e) => this.submitClick.bind(e)}>Submit</button> */}

                    </form>
                </div>
            </>
        )
    }
}
export default withAlert()(PaymentVerification);