import React, { Component } from 'react';
import Header from '../../component/header';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
import LoadingAnimation from '../../component/loadinganimation';
import { withAlert } from 'react-alert' //  to show the alert message
import EConfig from '../../data/enumconfig';
import './forgotpassword.scss';
let token = '';
class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            password: '',
            loading: false,
            fields: {}
        }
    }

    componentDidMount() {
        if (!sHelpers.defaultInputValidation()) {
            setTimeout(() => {
                this.props.history.push('/home');
            }, Config.minMsgTimeout);
            return false;
        }
        token = sHelpers.secureStorage.getItem('club.token');
        sHelpers.removeStoredData();
        sHelpers.mTop();
        //  this.setState({ loading: true });
    }

    componentDidCatch(error, errorInfo) {
        // console.log(error);
        // console.log(errorInfo);
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    handleContinueClick(e) {
        e.preventDefault();
        this.setState({ loading: true });
        sHelpers.mTop();

        setTimeout(() => {

            let fields = this.state.fields;
            //Mobile No

            if (!fields["membershipno"]) {
                this.props.alert.error("Membership Number cannot be empty");
                document.getElementById('input-membership-no').focus();
                this.setState({ loading: false });
                return false;
            }
            else if (!fields["mobileno"]) {
                this.props.alert.error("Mobile number cannot be empty");
                document.getElementById('input-mobile-no').focus();
                this.setState({ loading: false });
                return false;
            }


            let postObj = {
                MembershipNo: this.state.fields["membershipno"],
                Phone: this.state.fields["mobileno"],
                CrossPlatform: EConfig.crossPlatform.Web
            }
            //  console.log(postObj);


            fetch(Config.baseClubUrl + 'ClubForgotPasswordOTP', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                .then(res => res.json())
                .then(response => {
                    console.log(response);
                    if (response.errorCode === 0) {
                        this.props.alert.success(response.message);
                        sHelpers.secureStorage.setItem('club.mobile.no', this.state.fields["mobileno"]);
                        sHelpers.secureStorage.setItem('club.membership.no', this.state.fields["membershipno"]);
                        setTimeout(() => {
                            this.setState({ loading: false });
                            this.props.history.push('/confirmpassword');
                        }, Config.averageMsgTimeout);
                    }
                    else {
                        this.setState({ loading: false });
                        this.props.alert.error(response.message);
                    }
                })
                .catch(err => {
                    //  console.log(err);
                    this.setState({ loading: false });
                    this.props.alert.error(err);
                });
        }, Config.minMsgTimeout);
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                <Header></Header>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="wrapper-content bg-white">
                                <div className="section-scroll" id="section-apply">
                                    <div className="bg-light p-5">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="section-title mb60 text-center">
                                                    <h1>Forgot Password</h1>
                                                    <p>Please enter your membership number and mobile number</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="offset-xl-4 col-xl-8 offset-md-2 col-md-12 offset-md-2 col-md-12 col-sm-12 col-12">
                                                <form className="" onSubmit={this.handleContinueClick.bind(this)}>
                                                    <fieldset>
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12 form-group ">
                                                                <label className="control-label" htmlFor="membership-no">Membership Number</label>
                                                                <div className="">
                                                                    <input id="input-membership-no" type="text" placeholder="Membership Number" className="form-control input-md" maxLength="15" onChange={this.handleChange.bind(this, "membershipno")} value={this.state.fields["membershipno"]} autoFocus autoComplete="off" />
                                                                    <span className="help-block"> </span> </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 form-group ">
                                                                <label className="control-label" htmlFor="mobile-no">Mobile Number</label>
                                                                <div className="">
                                                                    <input id="input-mobile-no" type="text" placeholder="Mobile Number" className="form-control" maxLength="15" onChange={this.handleChange.bind(this, "mobileno")} value={this.state.fields["mobileno"]} autoComplete="off" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="form-group col-xl-4 col-lg-12 col-md-6 col-sm-12 col-12">
                                                                <div className="">
                                                                    <button id="input-continue" name="input-continue" className="btn btn-primary btn-block btn-cusm btn-sm btn-bg">Continue</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withAlert()(ForgotPassword);