import React, { Component } from 'react';
import Header from '../../component/header';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
import 'react-slideshow-image/dist/styles.css';
import './normal.scss';
import { withAlert } from 'react-alert' // HOC to show the alert message
import * as QueryString from "query-string";
import ModalImage from "react-modal-image";
let token = '';
let inputParam = '';
class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultsliderImages: [
                {
                    imageUrl: "../t1/assets/images/slider/club-lucid-no-image.jpg",
                }
            ],
            sliderImages: [],
            pageHeading: "Club",
            pageDescription: "",
            menuList: sHelpers.secureStorage.getItem('club.menus'),
            pageId: 0,
            pageName: "",
            pageListView: []
        }
    }

    componentDidMount() {
        if (!sHelpers.defaultInputValidation()) {
            setTimeout(() => {
                this.props.history.push('/pageerror');
            }, Config.minMsgTimeout);
            return false;
        }

        token = sHelpers.secureStorage.getItem('club.token');
        /// Get Content Data

        inputParam = QueryString.parse(window.location.search);
        // console.log(inputParam.pid);
        // console.log(inputParam.ptid);

        this.setState({ pageId: parseInt(inputParam.pid) })

        if (inputParam.pid === undefined || inputParam.pid === "" || inputParam.pid === null) {
            this.props.history.push('/home');
            return false;
        }


        sHelpers.mTop();
        this.getPageDetails();
    }



    getPageDetails() {

        this.setState({ loading: true });
        fetch(Config.baseClubUrl + 'GetClubPageDetail?PageId=' + inputParam.pid + '&PageType=' + Config.pageType.listView, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                console.log(response);
                if (response.errorCode === 0) {
                    //Get
                    this.setState({ pageListView: response.pageDetail.pageListView, pageHeading: response.pageDetail.pageTitle, pageDescription: response.pageDetail.preview, pageName: response.pageDetail.pageName });
                    // console.log(this.state.sliderImages);
                }
                else {
                    this.props.alert.error(response.message);
                }
                this.setState({ loading: false });
            })
            .catch(err => {
                this.setState({ loading: false });
                this.props.alert.error(err);
            });
    }

    render() {

        return (

            <>
                {/* <!-- /.top-bar --> */}
                <Header></Header>
                <div className="section-space20">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-md-12 col-md-12 col-sm-12 col-12">
                                <div className="mb60 text-center section-title">
                                    <h1>{this.state.pageName}</h1>
                                </div>
                                <div className="mb60 section-title">
                                    {this.state.pagePreview !== "" &&
                                        <p className="home-content" dangerouslySetInnerHTML={{ __html: this.state.pageDescription }}></p>
                                    }
                                </div>

                                {/* <!-- /.section title start--> */}
                            </div>
                        </div>
                        {
                            this.state.pageListView.map(pl =>
                                <div class="row mb-3 border no-gutters">
                                    <div class="col-12 col-lg-3 col-md-3  d-flex align-items-center p-3 justify-content-center">
                                        {/* <img src={Config.imgBaseURL + pl.imagePathName + pl.imageName} alt="club-list-view" /> */}
                                        <ModalImage
                                            small={Config.imgBaseURL + pl.imagePathName + pl.imageName}
                                            large={Config.imgBaseURL + pl.imagePathName + pl.imageName}
                                            hideDownload={true}
                                            alt="club-list-view-img"
                                        />
                                    </div>
                                    <div class="p-4 col-12 col-lg-9 col-md-9 border-left ml-n1">
                                        <a href="#!">
                                            <h4 class="mb-3">{pl.listTitle}</h4>
                                        </a>
                                        <div class="row">
                                            <div class="col-lg-3 col-md-12 col-12">
                                                <div class="font-12">
                                                    <p dangerouslySetInnerHTML={{ __html: pl.preview }}></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </>
        )
    }
}
export default withAlert()(List);