import React, { Component } from 'react';
import Header from '../../component/header';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
import LoadingAnimation from '../../component/loadinganimation';
import { withAlert } from 'react-alert' // HOC to show the alert message
import EConfig from '../../data/enumconfig';
//import moment from "moment";
let token = '';
class PaymentProcess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            outstandingBalance: 0.00,
            otherAmount: 0,
            payNowStatus: "disabled",
            redirectUrl: '',
            paymentRequestCode: '',
            paymentRequestKey: '',
            styleSelectPayment: {
                border: "2px solid #28a745"
            },
            vendorPaymentList: [],
            isEnableHDFC: 0,
            isEnableRazorPay: 0,
            selectedVendorId: 0,
            selectedVendorName: "",
            successUrl: "",
            failureUrl: ""
        }
    }

    componentDidMount() {
        if (!sHelpers.defaultInputValidation()) {
            setTimeout(() => {
                this.props.history.push('/home');
            }, Config.minMsgTimeout);
            return false;
        }

        if (!sHelpers.LoggedinInputValidation()) {
            this.props.alert.error("Session Expired! Please log in again.");
            this.setState({ loading: true });
            setTimeout(() => {
                this.props.history.push('/home');
            }, Config.minMsgTimeout);
            return false;
        }

        token = sHelpers.secureStorage.getItem('club.token');

        let invalidInp = false;
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.outstandingBalance === undefined || this.props.location.state.outstandingBalance === null)
                invalidInp = true;


            if (invalidInp) {
                this.props.alert.error("Invalid input value");
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/dashboard');
                }, Config.minMsgTimeout);

            }
            else this.setState({ outstandingBalance: this.props.location.state.outstandingBalance })
        }
        else {
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/dashboard');
            }, Config.minMsgTimeout);
        }


        //Get Payment Vendor List
        fetch(Config.baseClubUrl + 'GetClubAppSetting?ModuleCode=PAYT', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                //  console.log(response);
                if (response.errorCode === 0) {
                    let vendor1 = response.appSettingList.filter(v => v.keyName === "VendorStatus1");
                    if (vendor1.length > 0) {
                        this.setState({ isEnableHDFC: parseInt(vendor1[0].keyValue) });
                        if (parseInt(vendor1[0].keyValue) === 1) {
                            this.setState({ selectedVendorId: 1, selectedVendorName: "HDFC" });
                        }
                    }

                    let vendor2 = response.appSettingList.filter(v => v.keyName === "VendorStatus2");
                    if (vendor2.length > 0) {
                        this.setState({ isEnableRazorPay: parseInt(vendor2[0].keyValue) });
                        if (parseInt(vendor2[0].keyValue) === 1) {
                            if (this.state.selectedVendorId === 0) {
                                this.setState({ selectedVendorId: 2, selectedVendorName: "Razorpay" });
                            }
                        }
                    }
                    //console.log(this.state.selectedVendorId);
                    this.setState({ loading: false });
                }
                else {
                    this.setState({ loading: false });
                    this.props.alert.error(response.message);
                }
            })
            .catch(err => {
                //  console.log(err);
                this.setState({ loading: false });
                this.props.alert.error(err);
            });

    }

    handlePayNowClick(e) {
        e.preventDefault();
        this.setState({ loading: true });
        window.scrollTo(0, 0);
        let outStandingAmount = parseFloat(this.state.outstandingBalance > 0 ? this.state.outstandingBalance : 0);
        if (parseFloat(this.state.otherAmount) === 0) {
            if (outStandingAmount === 0) {
                this.props.alert.error("Please enter a valid amount");
                this.setState({ loading: false });
                return false;
            }
        }

        sHelpers.secureStorage.removeItem('club.paymentInitiate.Id');
        setTimeout(() => {
            let postObj = {
                MembershipId: parseInt(sHelpers.secureStorage.getItem('club.membership.id')),
                MemberName: sHelpers.secureStorage.getItem("club.displayname"),
                MembershipNo: sHelpers.secureStorage.getItem("club.membership.no"),
                VendorId: this.state.selectedVendorId,
                VendorName: this.state.selectedVendorName,
                Phone: sHelpers.secureStorage.getItem("club.mobile.no"),
                Amount: this.state.otherAmount > 0 ? this.state.otherAmount : this.state.outstandingBalance,
                Currency: "INR",
                DeviceId: 0,
                CrossPlatform: EConfig.crossPlatform.Web
            }
            //console.log(postObj);

            fetch(Config.baseClubUrl + 'InitiatePayment', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                .then(res => res.json())
                .then(response => {
                    //  console.log(response);
                    if (response.errorCode === 0) {

                        this.setState({
                            paymentRequestCode: response.paymentRequestCode, paymentRequestKey: response.paymentRequestKey, redirectUrl: response.redirectUrl,
                            vendorOrderId: response.vendorOrderId,
                            failureUrl: response.failure, successUrl: response.successUrl
                        });
                        sHelpers.secureStorage.setItem('club.paymentInitiate.Id', response.paymentInitiatedId);


                        if (this.state.selectedVendorId === Config.paymentVendor.razorPay) {

                            setTimeout(() => {
                                this.submitClick.click();
                            }, 500);
                        }
                        else {
                            setTimeout(() => {
                                this.inputElement.click();
                            }, 500);
                        }

                    }
                    else {
                        this.setState({ loading: false });
                        this.props.alert.error(response.message);
                    }
                })
                .catch(err => {
                    //  console.log(err);
                    this.setState({ loading: false });
                    this.props.alert.error(err);
                });
        }, Config.minMsgTimeout);

    }

    handlechange(e) {
        //console.log(e.target.value);
        this.setState({ otherAmount: e.target.value.substr(0, 6) });
    };

    handleSelectVendor(id, name) {
        this.setState({ selectedVendorId: id, selectedVendorName: name });
    }
    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                <Header></Header>
                {/* <div class="section-space80 bg-gradient"> */}
                <div className="section-space40 pinside60 bg-primary-light">
                    {/* <div class="container">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="page-breadcrumb">
                                    <ol class="breadcrumb">
                                        <li><a href="/home">Home</a></li>
                                        <li class="active">Payment</li>
                                    </ol>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="bg-white pinside30">
                                    <div class="row align-items-center">
                                        <div class="col-xl-5 col-lg-5 col-md-4 col-sm-4 col-4">
                                            <h1 class="page-title">{sHelpers.secureStorage.getItem("club.displayname")}</h1>
                                            <small>{sHelpers.secureStorage.getItem("club.membership.no")}</small>
                                        </div>
                                        <div class="col-xl-7 col-lg-8 col-md-8 col-sm-8 col-8">
                                            <div class="row">
                                                <div class="col-xl-8 col-lg-3 col-md-12 d-none d-xl-block d-lg-block">
                                                    <div class="rate-block text-right">
                                                        <h2 class="rate-number">
                                                            {this.state.balance < 0 && <span className="balance-primary">₹ {this.state.balance}</span>}
                                                            {this.state.balance >= 0 && <span className="balance-secondary">₹ {this.state.balance}</span>}
                                                        </h2>
                                                        <small>Balance as on <br />{this.state.balanceAsOn}</small>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-9 col-md-12 col-sm-12 col-12">
                                                    <button id="input-verify" name="input-verify" class="btn btn-primary btn-block">Pay Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sub-nav" id="sub-nav">
                                    <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="tab-1" data-toggle="tab" href="#trans-list" role="tab" aria-controls="service1" aria-selected="true">Transaction List</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="tab-2" data-toggle="tab" href="#pending-trans-list" role="tab" aria-controls="service2" aria-selected="false">Pending Transaction</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="tab-3" data-toggle="tab" href="#payment-history" role="tab" aria-controls="service3" aria-selected="false">Payment History</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="wrapper-content bg-white p-3 p-lg-5">
                                <div class="loan-eligibility-block">
                                    <div class="row">
                                        <div class="offset-xl-4 col-xl-8 offset-md-4 col-md-12 offset-md-2 col-md-12 col-sm-12 col-12">
                                            <h2 class="mb20">Payment Details</h2>
                                            <div class="row">
                                                <div name="formval2" class="form-horizontal loan-eligibility-form">
                                                    <div class="form-group">
                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <label for="balance" class="control-label">Total Outstanding Balance</label>
                                                            <h2 class="font-weight-bold mb-0">₹ {this.state.outstandingBalance > 0 ? this.state.outstandingBalance : 0}</h2>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <label for="income" class="control-label">Other Amount</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text">₹</span>
                                                                </div>
                                                                <input type="number" class="form-control" id="input-other-amount" name="input-other-amount" placeholder="Enter Other Amount" maxLength="6" value={this.state.otherAmount} onChange={this.handlechange.bind(this)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="col-lg-6 col-md-6 col-12">
                                                            <h5 class="mb-3">Payment</h5>
                                                            {this.state.isEnableHDFC === 1 &&
                                                                <div class="card bg-white mb-3 shadow">
                                                                    {/* <span class="badge badge-info badge-rate font-11 float-right">Select</span> */}
                                                                    <div class="card-body p-4" style={this.state.selectedVendorId === 1 ? this.state.styleSelectPayment : {}} onClick={this.handleSelectVendor.bind(this, 1, "HDFC")}>
                                                                        <div class="media align-items-center">
                                                                            <img src="../t1/assets/images/hdfc.png" alt="hdfc-logo" class="mr-3 w-25" />
                                                                            <div class="media-body">
                                                                                <h6 class="mb-1">HDFC</h6>
                                                                                {/* <span><img src="../t1/assets/images/guaranteed.svg"></img></span> */}
                                                                                {/* <p class="font-10 mb-0">
                                                                                <span class="font-14 text-dark">0%</span>
                                                                                <span class="mr-1">test</span>
                                                                                <span><i class="fas fa-check-circle mr-1 text-success"></i>test</span>
                                                                            </p> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {this.state.isEnableRazorPay === 1 &&
                                                                <div class="card bg-white mt-2 mb-3 shadow">
                                                                    <div class="card-body p-4" style={this.state.selectedVendorId === 2 ? this.state.styleSelectPayment : {}} onClick={this.handleSelectVendor.bind(this, 2, "Razorpay")}>
                                                                        <div class="media align-items-center">
                                                                            <img src="../t1/assets/images/razorpay.png" alt="razorpay" class="mr-3 w-25" />
                                                                            <div class="media-body">
                                                                                <h6 class="mb-1">Razorpay</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <button type="button" class="btn btn-primary mr10 btn-cusm btn-bg" onClick={this.handlePayNowClick.bind(this)} {...this.state.payNowStatus} >Pay Now</button>
                                                            <a href="/dashboard"><button type="button" class="btn btn-secondary btn-cusm btn-bg">Cancel</button></a>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div>
                                                    <form id="razorpay-submit" method="POST" action={this.state.redirectUrl} onSubmit={this.handleSubmit}>
                                                        <input type="hidden" name="key_id" value={this.state.paymentRequestKey} />
                                                        <input type="hidden" name="order_id" value={this.state.vendorOrderId} />
                                                        <input type="hidden" name="name" value={sHelpers.secureStorage.getItem('club.property.name')} />
                                                        <input type="hidden" name="description" value={sHelpers.secureStorage.getItem('club.property.name')} />
                                                        <input type="hidden" name="image" value="" />
                                                        <input type="hidden" name="prefill[name]" value="" />
                                                        <input type="hidden" name="prefill[contact]" value={sHelpers.secureStorage.getItem("club.mobile.no")} />
                                                        <input type="hidden" name="prefill[email]" value="test@mail.com" />
                                                        <input type="hidden" name="notes[shipping address]" value=" " />
                                                        <input type="hidden" name="callback_url" value={this.state.successUrl} />
                                                        <input type="hidden" name="cancel_url" value={this.setState.failureUrl} />
                                                        <button style={{ display: "none" }} ref={input => this.submitClick = input}>Submit</button>
                                                        {/* <button style={{ display: "none" }} ref={(e) => this.submitClick.bind(e)}>Submit</button> */}

                                                    </form>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <form id="hdfc-submit" method="POST" action={this.state.redirectUrl} onSubmit={this.handleSubmit}>
                        <input type="hidden" name="encRequest" value={this.state.paymentRequestKey} />
                        <input type="hidden" name="access_code" value={this.state.paymentRequestCode} />
                        <button style={{ display: "none" }} ref={input => this.inputElement = input}>Submit</button>
                        {/* <button style={{ display: "none" }} ref={(e) => this.submitClick.bind(e)}>Submit</button> */}

                    </form>
                </div>
            </>
        )
    }
}
export default withAlert()(PaymentProcess);