import React, { Component } from 'react';
import Header from '../../component/header';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
import 'react-slideshow-image/dist/styles.css';
import { withAlert } from 'react-alert' // HOC to show the alert message
import * as QueryString from "query-string";
import EConfig from '../../data/enumconfig';
import LoadingAnimation from '../../component/loadinganimation';


let token = '';
let inputParam = '';
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageHeading: "Member",
            pageDescription: "",
            menuList: sHelpers.secureStorage.getItem('club.menus'),
            pageId: 0,
            pageName: "",
            imageList: [],
            enquiryName: "",
            enquirymobileno: "",
            enquiryMembershiId: 0,
            enquiryMembershipNo: "",
            requestIp: "127.0.0.1",
            enquiryEmail: "",
            enquiryMessage: ""
        }
    }

    async componentDidMount() {
        if (!sHelpers.defaultInputValidation()) {
            setTimeout(() => {
                this.props.history.push('/pageerror');
            }, Config.minMsgTimeout);
            return false;
        }

        token = sHelpers.secureStorage.getItem('club.token');
        /// Get Content Data

        inputParam = QueryString.parse(window.location.search);

        this.setState({ pageId: parseInt(inputParam.pid) })

        if (inputParam.pid === undefined || inputParam.pid === "" || inputParam.pid === null) {
            this.props.history.push('/home');
            return false;
        }

        if (sHelpers.secureStorage.getItem("club.user.login")) {
            this.setState({ enquiryName: sHelpers.secureStorage.getItem("club.displayname"), enquirymobileno: sHelpers.secureStorage.getItem("club.mobile.no"), enquiryMembershiId: parseInt(sHelpers.secureStorage.getItem("club.membership.id")), enquiryMembershipNo: sHelpers.secureStorage.getItem("club.membership.no") })
        }

        const response = await fetch('https://geolocation-db.com/json/');
        const data = await response.json();
        this.setState({ requestIp: data.IPv4 })

        sHelpers.mTop();
        this.getPageDetails();
    }

    handleSubmitClick(e) {
        e.preventDefault();
        this.setState({ loading: true });
        sHelpers.mTop();

        setTimeout(() => {

            if (this.state.enquiryMessage.trim() === "") {
                this.props.alert.error("Message cannot be empty");
                document.getElementById('enquiry-message').focus();
                this.setState({ loading: false });
                return false;
            }

            let postObj = {
                MembershipId: this.state.enquiryMembershiId,
                MemberName: this.state.enquiryName,
                MembershipNo: this.state.enquiryMembershipNo,
                Phone: this.state.enquirymobileno,
                EmailId: this.state.enquiryEmail,
                Comments: this.state.enquiryMessage,
                IP: this.state.requestIp,
                CrossPlatform: EConfig.crossPlatform.Web
            }
            console.log(postObj);

            fetch(Config.baseClubUrl + 'SaveClubEnquiry', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                .then(res => res.json())
                .then(response => {
                    //console.log(response);
                    if (response.errorCode === 0) {
                        this.props.alert.success(response.message);
                        this.setState({ loading: false });
                        // Clear Data

                        this.setState({ enquiryMessage: "", enquiryEmail: "" })
                    }
                    else {
                        this.setState({ loading: false });
                        this.props.alert.error(response.message);
                    }
                })
                .catch(err => {
                    //  console.log(err);
                    this.setState({ loading: false });
                    this.props.alert.error(err);
                });
        }, Config.averageMsgTimeout);
    }

    handleEmailChange = event => {
        const val = event.target.value;
        this.setState({ enquiryEmail: val });
    };

    handleMessageChange = event => {
        const val = event.target.value;
        this.setState({ enquiryMessage: val });
    };


    getPageDetails() {

        this.setState({ loading: true });
        fetch(Config.baseClubUrl + 'GetClubPageDetail?PageId=' + inputParam.pid + '&PageType=' + Config.pageType.contact, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                console.log(response);
                if (response.errorCode === 0) {
                    //Get
                    this.setState({ imageList: response.pageDetail.imageList, pageHeading: response.pageDetail.pageTitle, pageDescription: response.pageDetail.preview, pageName: response.pageDetail.pageName });
                    // console.log(this.state.sliderImages);
                }
                else {
                    this.props.alert.error(response.message);
                }
                this.setState({ loading: false });
            })
            .catch(err => {
                this.setState({ loading: false });
                this.props.alert.error(err);
            });
    }

    render() {

        return (

            <>
                {/* <!-- /.top-bar --> */}
                {this.state.loading && <LoadingAnimation />}
                <Header></Header>
                <div className="section-space20">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-md-12 col-md-12 col-sm-12 col-12">
                                <div className="mb60 text-center section-title">
                                    <h1>{this.state.pageName}</h1>
                                </div>
                                <div className="mb60 section-title">
                                    <div className="col-md-12">
                                        <div className="wrapper-content bg-white p-3 p-lg-5">
                                            {(sHelpers.secureStorage.getItem("club.user.login")) &&
                                                <div className="row">
                                                    <form className="contact-us" onSubmit={this.handleSubmitClick.bind(this)}>
                                                        <div className=" ">
                                                            <div className="row">
                                                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                                    <div className="form-group">
                                                                        <label className="sr-only control-label" htmlFor="enquiry-name">name<span className=" "> </span></label>
                                                                        <input id="enquiry-name" name="enquiry-name" type="text" placeholder="Name" className="form-control input-md" readOnly value={this.state.enquiryName} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                                    <div className="form-group">
                                                                        <label className="sr-only control-label" htmlFor="enquiry-phone">Phone<span className=" "> </span></label>
                                                                        <input id="enquiry-phone" name="enquiry-phone" type="text" placeholder="Phone" className="form-control input-md" readOnly value={this.state.enquirymobileno} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                                                    <div className="form-group">
                                                                        <label className="sr-only control-label" htmlFor="enquiry-email">Email<span className=" "> </span></label>
                                                                        <input id="enquiry-email" name="enquiry-email" type="email" placeholder="Email" className="form-control input-md" onChange={(e) => this.handleEmailChange(e)} value={this.state.enquiryEmail} />
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                    <div className="form-group">
                                                                        <label className="control-label" htmlFor="enquiry-message"> </label>
                                                                        <textarea className="form-control" id="enquiry-message" rows="7" name="enquiry-message" placeholder="Message*" onChange={(e) => this.handleMessageChange(e)} value={this.state.enquiryMessage}></textarea>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                    <button type="submit" className="btn btn-primary btn-bg">Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            }
                                            <div className="row mt60">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <div className="mb60">
                                                        <div className="home-content" dangerouslySetInnerHTML={{ __html: this.state.pageDescription }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>









                                </div>

                                {/* <!-- /.section title start--> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withAlert()(Contact);