import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './header.scss';
import sHelpers from '../../modules/helpers/statichelpers';
import { withAlert } from 'react-alert' // HOC to show the alert message
import Config from '../../data/siteconfig';
import Marquee from "react-fast-marquee";
//import sidemenulogo from './home-logo.png';

// const menuItems = [
//     { menuId: 1, menuName: 'Home', iconsName: 'fas fa-home', routePath: "/home" },
//     { menuId: 2, menuName: 'My Orders', iconsName: 'fa fa-shopping-cart sidebar-icon', routePath: "/myorders" },
//     // { menuId: 2, menuName: 'My Bills', iconsName: 'fa fa-money', routePath: "/mybills" },
//     // { menuId: 3, menuName: 'Contact Us', iconsName: 'fa fa-phone sidebar-icon', routePath: "/home" },
//     { menuId: 4, menuName: 'Logout', iconsName: 'fa fa-sign-out-alt', routePath: "/logout" }
// ];

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            menuList: sHelpers.secureStorage.getItem('club.menus'),
            newsList: sHelpers.secureStorage.getItem('club.news')
        }
    }

    componentDidMount() {

        var today = new Date(), date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

        // console.log(date);
        // console.log(sHelpers.secureStorage.getItem('club.user.loggedin.date'));
        // Logged in validation
        if (sHelpers.secureStorage.getItem('club.user.login') !== null) {
            if (date !== sHelpers.secureStorage.getItem('club.user.loggedin.date')) {
                sHelpers.secureStorage.setItem('club.user.login', false);
                sHelpers.secureStorage.setItem('club.user.loggedin.date', "");
                sHelpers.secureStorage.setItem("club.displayname", "");
            }
        }
        else {
            sHelpers.secureStorage.setItem('club.user.login', false);
            sHelpers.secureStorage.setItem("club.displayname", "");
            sHelpers.secureStorage.setItem('club.user.loggedin.date', "");
        }


    }

    redirectToLogout(e) {
        e.preventDefault();
        sHelpers.removeStoredData();
        this.props.alert.info("You have logged out successfully.");
        this.setState({ loading: true });
    }

    render() {
        var logo = sHelpers.secureStorage.getItem('club.logo');
        return (
            <>
                <div className="header-topbar d-lg-block ">
                    {/* <!-- top-bar --> */}
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 ">
                                 <p className="mail-text">Welcome to Lucid Club</p> 
                            </div>*/}
                            {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                                <p className="mail-text text-center">Call us at 1-800-123-4567</p>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 ">
                                <p className="mail-text text-center">Mon to fri 10:00am - 06:00pm</p>
                            </div> */}
                            {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 ">
                                {sHelpers.secureStorage.getItem("club.displayname") !== null && <p className="mail-text text-center">Welcome {sHelpers.secureStorage.getItem("club.displayname")} </p>}
                            </div> */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  d-xl-block d-lg-block">
                                <div>
                                    {(sHelpers.secureStorage.getItem("club.displayname") !== null && sHelpers.secureStorage.getItem("club.displayname") !== "") && <div className="mail-text text-right col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"><span style={{ color: "#dc3545" }}>Welcome {sHelpers.secureStorage.getItem("club.displayname")}</span> </div>}
                                </div>
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="row">
                                            {/* <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 text-right"><span className="new-bg">News:</span></div> */}
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <Marquee
                                                    speed={90}
                                                    gradient={false}
                                                >
                                                    {

                                                        this.state.newsList.map((each, index) =>

                                                            <div style={{ marginRight: "20px", fontSize: "14px", textTransform: "none" }} id={"club-news-" + index}>{each.newsPageContent}</div>


                                                        )

                                                    }

                                                </Marquee>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 col-12 text-right">
                                <div className="top-nav">
                                    {/* <marquee behavior='scroll' scrollamount='5' width='50%' direction='left' height='50px'>jk</marquee> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /.top-bar --> */}
                {/* <div className="sticky-top">
                    <nav className="navbar navbar-expand-lg navbar-light bg-white py-3">
                        <div className="container">
                            <NavLink to="/home" className="navbar-brand">
                                <img src={logo}
                                    alt="Lucid-Club-App" />
                            </NavLink>
                            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span className="icon-bar top-bar mt-0"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item dropdown">
                                        <NavLink to="/home" className="nav-link">
                                            Home
                                        </NavLink>

                                    </li>
                                    <li className="nav-item dropdown ">
                                        <NavLink to="/home" className="nav-link">
                                            Club Information
                                        </NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink to="/home" className="nav-link">
                                            Golf
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/home" className="nav-link">
                                            Tournament
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/home" className="nav-link">
                                            Facilities
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/home" className="nav-link">
                                            Contact
                                        </NavLink>
                                    </li>
                                    {
                                        (!sHelpers.secureStorage.getItem("club.user.login")) &&
                                        <>
                                            <li className="nav-item">
                                                <NavLink to="/login">
                                                    <div className="btn btn-secondary btn-sm btn-cusm mr10">
                                                        Login
                                                    </div>
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/register">
                                                    <div className="btn btn-primary btn-sm btn-cusm">
                                                        Register
                                                    </div>
                                                </NavLink>
                                            </li>
                                        </>
                                    }
                                    {(sHelpers.secureStorage.getItem("club.user.login")) &&
                                        <>
                                            <li className="nav-item">
                                                <NavLink to="/dashboard" className="nav-link">
                                                    Account
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/logout">
                                                    <div className="nav-link">
                                                        Logout
                                                    </div>
                                                </NavLink>

                                            </li>
                                        </>
                                    }
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div> */}


                <div className="sticky-top bg-white">
                    <div className="container py-2">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="logo">
                                    {/* <a href="index.html"><img src="images/borrow-loan-logo.svg" alt="Borrow - Loan Company Website Template" /></a> */}
                                    <NavLink to="/home" className="navbar-brand">
                                        <img src={logo}
                                            alt="Lucid-Club-App" />
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6 ">
                                <div className="header-action d-flex justify-content-end">
                                    {
                                        (!sHelpers.secureStorage.getItem("club.user.login")) &&
                                        <>
                                            <NavLink to="/login">
                                                <div className="btn btn-secondary btn-sm btn-cusm mr10 login-register-btn btn-bg">
                                                    Login
                                                </div>
                                            </NavLink>
                                            <NavLink to="/register">
                                                <div className="btn btn-primary btn-sm btn-cusm login-register-btn btn-bg">
                                                    Register
                                                </div>
                                            </NavLink>
                                        </>
                                    }
                                    {(sHelpers.secureStorage.getItem("club.user.login")) &&
                                        <>

                                            <NavLink to="/dashboard" className="nav-link">
                                                Account
                                            </NavLink>


                                            <NavLink to="/logout">
                                                <div className="nav-link">
                                                    Logout
                                                </div>
                                            </NavLink>


                                        </>
                                    }
                                    {/* <a href="#!" className="btn btn-primary btn-sm  d-none d-xl-block d-lg-block">Loan Calculator</a>
                                    <a href="#!" className="btn btn-secondary btn-sm ">Get Quote Now</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="navbar navbar-expand-lg navbar-light bg-white py-3 py-lg-0 border-top border-bottom">
                        <div className="container">
                            <a className="d-xl-none d-lg-none" href="#!">Menu</a>
                            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="icon-bar top-bar mt-0"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav nav-justified w-lg-75 mt-2 mt-lg-0">

                                    {this.state.menuList.map(m =>
                                        <>
                                            <li className={"nav-item " + (m.subMenus.length > 0 ? "dropdown " : " ") + " border-left-lg"} id={"mnu-li-" + m.menuId + ""}>
                                                {
                                                    m.menuType === Config.pageType.home &&
                                                    <NavLink to="/home" className="nav-link">
                                                        {m.menuName}
                                                    </NavLink>
                                                }
                                                {m.menuType !== Config.pageType.home &&
                                                    <>
                                                        {/* <NavLink to={"" + (m.linkPage > 0 ? "/page" : "#") + ""} className={"nav-link  " + (m.subMenus.length > 0 ? "dropdown-toggle " : " ") + ""} id={"navbar-li-" + m.menuId + ""}>
                                                            {m.menuName}
                                                        </NavLink> */}
                                                        {m.linkPage > 0 &&
                                                            <>
                                                                {m.menuType === Config.pageType.normal &&

                                                                    < a className={"nav-link  " + (m.subMenus.length > 0 ? "dropdown-toggle " : " ") + ""} id={"navbar-li-" + m.menuId + ""} data-toggle={m.subMenus.length > 0 ? "dropdown" : ""} href={"/page?pid=" + m.menuId + "&ptid=" + m.menuType + ""}  >
                                                                        {m.menuName}
                                                                    </a>
                                                                }

                                                                {m.menuType === Config.pageType.gallery &&

                                                                    < a className={"nav-link  " + (m.subMenus.length > 0 ? "dropdown-toggle " : " ") + ""} id={"navbar-li-" + m.menuId + ""} data-toggle={m.subMenus.length > 0 ? "dropdown" : ""} href={"/gallery?pid=" + m.menuId + ""}  >
                                                                        {m.menuName}
                                                                    </a>
                                                                }
                                                                {m.menuType === Config.pageType.contact &&

                                                                    < a className={"nav-link  " + (m.subMenus.length > 0 ? "dropdown-toggle " : " ") + ""} id={"navbar-li-" + m.menuId + ""} data-toggle={m.subMenus.length > 0 ? "dropdown" : ""} href={"/contact?pid=" + m.menuId + ""}  >
                                                                        {m.menuName}
                                                                    </a>
                                                                }
                                                                {m.menuType === Config.pageType.member &&

                                                                    < a className={"nav-link  " + (m.subMenus.length > 0 ? "dropdown-toggle " : " ") + ""} id={"navbar-li-" + m.menuId + ""} data-toggle={m.subMenus.length > 0 ? "dropdown" : ""} href={"/member?pid=" + m.menuId + ""}  >
                                                                        {m.menuName}
                                                                    </a>
                                                                }
                                                                {m.menuType === Config.pageType.listView &&

                                                                    < a className={"nav-link  " + (m.subMenus.length > 0 ? "dropdown-toggle " : " ") + ""} id={"navbar-li-" + m.menuId + ""} data-toggle={m.subMenus.length > 0 ? "dropdown" : ""} href={"/list?pid=" + m.menuId + ""}  >
                                                                        {m.menuName}
                                                                    </a>
                                                                }

                                                            </>
                                                        }
                                                        {m.linkPage === 0 &&
                                                            <NavLink to="#" className={"nav-link  " + (m.subMenus.length > 0 ? "dropdown-toggle " : " ") + ""} id={"navbar-li-" + m.menuId + ""} data-toggle={m.subMenus.length > 0 ? "dropdown" : ""}>
                                                                {m.menuName}
                                                            </NavLink>
                                                        }
                                                    </>


                                                    // <a className={"nav-link  " + (m.subMenus.length > 0 ? "dropdown-toggle " : " ") + ""} id={"navbar-li-" + m.menuId + ""} data-toggle="dropdown" href="#!" >
                                                    //     {m.menuName}
                                                    // </a>
                                                }




                                                {m.subMenus.length > 0 &&
                                                    <ul className="dropdown-menu" aria-labelledby={"navbar-li-" + m.menuId + ""}>
                                                        {m.subMenus.map(sm =>
                                                            <li className={(sm.subMenus.length > 0 ? " dropdown-item dropright" : "")}>
                                                                {sm.linkPage > 0 &&
                                                                    <>
                                                                        {sm.menuType === Config.pageType.normal &&
                                                                            <a className={(sm.subMenus.length > 0 ? " dropdown-link dropdown-toggle" : "dropdown-item")} href={"/page?pid=" + sm.menuId + "&ptid=" + sm.menuType + ""} id={"navbar-li-" + m.menuId + "-" + sm.menuId + ""}>
                                                                                {sm.menuName}
                                                                            </a>
                                                                        }
                                                                        {sm.menuType === Config.pageType.gallery &&
                                                                            <a className={(sm.subMenus.length > 0 ? " dropdown-link dropdown-toggle" : "dropdown-item")} href={"/gallery?pid=" + sm.menuId + ""} id={"navbar-li-" + m.menuId + "-" + sm.menuId + ""}>
                                                                                {sm.menuName}
                                                                            </a>
                                                                        }
                                                                        {sm.menuType === Config.pageType.contact &&
                                                                            <a className={(sm.subMenus.length > 0 ? " dropdown-link dropdown-toggle" : "dropdown-item")} href={"/contact?pid=" + sm.menuId + ""} id={"navbar-li-" + m.menuId + "-" + sm.menuId + ""}>
                                                                                {sm.menuName}
                                                                            </a>
                                                                        }
                                                                        {sm.menuType === Config.pageType.member &&
                                                                            <a className={(sm.subMenus.length > 0 ? " dropdown-link dropdown-toggle" : "dropdown-item")} href={"/member?pid=" + sm.menuId + ""} id={"navbar-li-" + m.menuId + "-" + sm.menuId + ""}>
                                                                                {sm.menuName}
                                                                            </a>
                                                                        }
                                                                        {sm.menuType === Config.pageType.listView &&
                                                                            <a className={(sm.subMenus.length > 0 ? " dropdown-link dropdown-toggle" : "dropdown-item")} href={"/list?pid=" + sm.menuId + ""} id={"navbar-li-" + m.menuId + "-" + sm.menuId + ""}>
                                                                                {sm.menuName}
                                                                            </a>
                                                                        }
                                                                    </>
                                                                }
                                                                {sm.linkPage === 0 &&
                                                                    <a className={(sm.subMenus.length > 0 ? " dropdown-link dropdown-toggle" : "dropdown-item")} href="#!" id={"navbar-li-" + m.menuId + "-" + sm.menuId + ""}>
                                                                        {sm.menuName}
                                                                    </a>
                                                                }
                                                                {/* {sm.linkPage > 0 &&

                                                                    <NavLink to={"/page?pid=" + sm.menuId + "&ptid=" + sm.menuType + ""} className={(sm.subMenus.length > 0 ? " dropdown-link dropdown-toggle" : "dropdown-item")} id={"navbar-li-" + m.menuId + "-" + sm.menuId + ""} onClick={this.redirectToPage.bind(this, m.menuId, m.menuType)} >
                                                                        {sm.menuName}
                                                                    </NavLink>

                                                                }
                                                                {sm.linkPage === 0 &&
                                                                    <NavLink to="#" className={(sm.subMenus.length > 0 ? " dropdown-link dropdown-toggle" : "dropdown-item")} id={"navbar-li-" + m.menuId + "-" + sm.menuId + ""}>
                                                                        {sm.menuName}
                                                                    </NavLink>
                                                                } */}
                                                                {/* 2nd menu list */}
                                                                {sm.subMenus.length > 0 &&
                                                                    <div className="dropdown-menu">
                                                                        {sm.subMenus.map(sm1 =>

                                                                            // <NavLink to={"/page?pid=" + sm1.menuId + "&ptid=" + sm1.menuType + ""} className="dropdown-item" id={"navbar-ll-" + m.menuId + "-" + sm.menuId + "-" + sm1.menuId + ""}>
                                                                            //     {sm1.menuName}1
                                                                            // </NavLink>
                                                                            <>
                                                                                {sm.menuType === Config.pageType.normal &&
                                                                                    <a className="dropdown-item" href={"/page?pid=" + sm1.menuId + "&ptid=" + sm1.menuType + ""} id={"navbar-ll-" + m.menuId + "-" + sm.menuId + "-" + sm1.menuId + ""}>
                                                                                        {sm1.menuName}
                                                                                    </a>
                                                                                }
                                                                                {sm.menuType === Config.pageType.gallery &&
                                                                                    <a className="dropdown-item" href={"/gallery?pid=" + sm1.menuId + ""} id={"navbar-ll-" + m.menuId + "-" + sm.menuId + "-" + sm1.menuId + ""}>
                                                                                        {sm1.menuName}
                                                                                    </a>
                                                                                }
                                                                                {sm.menuType === Config.pageType.contact &&
                                                                                    <a className="dropdown-item" href={"/contact?pid=" + sm1.menuId + ""} id={"navbar-ll-" + m.menuId + "-" + sm.menuId + "-" + sm1.menuId + ""}>
                                                                                        {sm1.menuName}
                                                                                    </a>
                                                                                }
                                                                                {sm.menuType === Config.pageType.member &&
                                                                                    <a className="dropdown-item" href={"/member?pid=" + sm1.menuId + ""} id={"navbar-ll-" + m.menuId + "-" + sm.menuId + "-" + sm1.menuId + ""}>
                                                                                        {sm1.menuName}
                                                                                    </a>
                                                                                }
                                                                                {sm.menuType === Config.pageType.listView &&
                                                                                    <a className="dropdown-item" href={"/list?pid=" + sm1.menuId + ""} id={"navbar-ll-" + m.menuId + "-" + sm.menuId + "-" + sm1.menuId + ""}>
                                                                                        {sm1.menuName}
                                                                                    </a>
                                                                                }

                                                                            </>
                                                                        )}
                                                                    </div>

                                                                }
                                                            </li>

                                                        )}
                                                    </ul>
                                                }
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div >

                {/* <!-- /.navigation start--> */}
                {/* /.search start--> */}
            </>
        )
    }
}
export default withAlert()(Header);