import React, { Component } from 'react';
import Header from '../../component/header';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
import LoadingAnimation from '../../component/loadinganimation';
import { withAlert } from 'react-alert' // HOC to show the alert message
import EConfig from '../../data/enumconfig';
import OtpInput from 'react-otp-input';
//import MaskedInput from "input-masked-react";
let token = '';
class LoginVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            mobileNo: '',
            maskMobileNo: '',
            password: '',
            membershipNo: '',
            otp: '',
            otpstyle: {
                width: "3em",
                fontSize: "14px",
                height: "52px",
                padding: "0px",
                color: "#555",
                backgroundImage: "none",
                backgroundColor: "#fff",
                borderRadius: "4px",
                boxShadow: "inset 0 0px 0px rgb(0 0 0 / 8%)",
                marginBottom: "10px",
                border: "2px solid #e6ecef"
            }
        };
        //  this.handleClick = this.handleSubmitClick.bind(this);
    }

    componentDidMount() {
        if (!sHelpers.defaultInputValidation()) {
            setTimeout(() => {
                this.props.history.push('/home');
            }, 1200);
            return false;
        }

        sHelpers.mTop();
        token = sHelpers.secureStorage.getItem('club.token');
        let invalidInp = false;

        /// Get Mobile No
        if (this.props.location.state !== undefined) {
            // console.log(this.props.location.state.mobileNo);
            // console.log(this.props.location.state.maskMobileNo);
            // console.log(this.props.location.state.password);
            // console.log(this.props.location.state.membershipNo);

            if (this.props.location.state.mobileNo === undefined || this.props.location.state.mobileNo === null || this.props.location.state.mobileNo === '')
                invalidInp = true;
            else if (this.props.location.state.maskMobileNo === undefined || this.props.location.state.maskMobileNo === null || this.props.location.state.maskMobileNo === '')
                invalidInp = true;
            else if (this.props.location.state.password === undefined || this.props.location.state.password === null || this.props.location.state.password === '')
                invalidInp = true;
            else if (this.props.location.state.membershipNo === undefined || this.props.location.state.membershipNo === null || this.props.location.state.membershipNo === '')
                invalidInp = true;

            if (invalidInp) {
                this.props.alert.error("Invalid input value");
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/register');
                }, Config.minMsgTimeout);

            }
            else this.setState({ mobileNo: this.props.location.state.mobileNo, maskMobileNo: this.props.location.state.maskMobileNo, password: this.props.location.state.password, membershipNo: this.props.location.state.membershipNo });
        }
        else {
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/register');
            }, Config.minMsgTimeout);
        }
        //  this.setState({ loading: true });
    }

    handleGoBack() {
        this.props.history.push('/register');
    }

    handleChangeOTP = (otp) => this.setState({ otp });

    handlechange = event => {
        this.setState({ otp: event.target.value });
    };

    handleVerifyClick(e) {
        e.preventDefault();
        this.setState({ loading: true });
        sHelpers.mTop();
        setTimeout(() => {

            // if (this.state.otp === '') {
            //     this.setState({ loading: false });
            //     this.props.alert.error("OTP cannot be empty ");
            //     document.getElementById('input-digit-code').focus();
            //     return false;
            // }

            if (this.state.otp === "") {
                this.props.alert.error("OTP Password cannot be empty");
                this.setState({ loading: false });
                return false;
            }

            let postObj = {

                OTP: this.state.otp,
                Password: this.state.password,
                AppDeviceId: "",
                TokenId: "",
                MemberShipNo: this.state.membershipNo,
                Phone: this.state.mobileNo,
                CrossPlatform: EConfig.crossPlatform.Web
            }
            // console.log(postObj);


            // Axios.post(Config.baseClubUrl + 'SaveClubMemberDetails', {
            fetch(Config.baseClubUrl + 'ValidateClubMember', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                .then(res => res.json())
                .then(response => {
                    //  console.log(response);
                    if (response.errorCode === 0) {
                        this.props.alert.success(response.message);
                        setTimeout(() => {
                            this.setState({ loading: false });
                            this.props.history.push('/login');
                        }, Config.averageMsgTimeout);
                    }
                    else {
                        this.setState({ loading: false });
                        this.props.alert.error(response.message);
                    }
                })
                .catch(err => {
                    //  console.log(err);
                    this.setState({ loading: false });
                    this.props.alert.error(err);
                });

        }, Config.minMsgTimeout);
    }


    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                <Header></Header>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="wrapper-content bg-white">
                                <div class="section-scroll" id="section-apply">
                                    <div class="bg-light p-5">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div class="section-title mb60 text-center">
                                                    <h1>Verification Code</h1>
                                                    <p>OTP is sent to your mobile number {this.state.maskMobileNo}.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="offset-xl-4 col-xl-12 offset-md-2 col-md-12 offset-md-2 col-md-12 col-sm-12 col-12">
                                                <form class="" onSubmit={this.handleVerifyClick.bind(this)}>
                                                    <fieldset>
                                                        {/* <div class="row">
                                                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 form-group ">
                                                                <div class="">                                                                    
                                                                    <input id="" name="input-digit-code" type="text" value={this.state.otp} onChange={(e) => this.handlechange(e)} placeholder="Enter the 4-digit code" class="form-control input-md" autoFocus maxLength="4" autoComplete="off" />
                                                                    <span class="help-block"> </span> </div>                                                               
                                                            </div>
                                                        </div> */}
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 form-group">
                                                                <label className="control-label" for="input-new-password">OTP</label>
                                                                <OtpInput
                                                                    value={this.state.otp}
                                                                    onChange={this.handleChangeOTP}
                                                                    numInputs={4}
                                                                    separator={<span>-</span>}
                                                                    inputStyle={this.state.otpstyle}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-xl-2 col-lg-12 col-md-6 col-sm-12 col-12">
                                                                <div class="">
                                                                    <button id="input-verify" name="input-verify" class="btn btn-primary btn-block btn-cusm btn-sm">Verify</button>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-xl-2 col-lg-12 col-md-6 col-sm-12 col-12">
                                                                <div class="">
                                                                    <button id="input-cancel" name="input-cancel" class="btn btn-secondary btn-block btn-cusm btn-sm" onClick={this.handleGoBack.bind(this)}>Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withAlert()(LoginVerification);