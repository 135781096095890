import React, { Component } from 'react';
import Header from '../../component/header';
import { Zoom } from 'react-slideshow-image';
import Config from '../../data/siteconfig';
import sHelpers from '../../modules/helpers/statichelpers';
import 'react-slideshow-image/dist/styles.css';
import './normal.scss';
import { withAlert } from 'react-alert' // HOC to show the alert message
import * as QueryString from "query-string";
//import sHelpers from '../../modules/helpers/statichelpers';
// const slideImages = [
//     '../t1/assets/images/slider/slider-1.jpg',
//     '../t1/assets/images/slider/slider-2.jpg',
//     '../t1/assets/images/slider/slider-3.jpg',
//     '../t1/assets/images/slider/slider-4.jpg',
//     '../t1/assets/images/slider/slider-5.jpg',
// ];
const zoomInProperties = {
    indicators: true,
    scale: 1.4
}
let token = '';
let inputParam = '';
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultsliderImages: [
                {
                    imageUrl: "../t1/assets/images/slider/club-lucid-no-image.jpg",
                }
            ],
            sliderImages: [],
            pageHeading: "Club",
            pageDescription: "",
            menuList: sHelpers.secureStorage.getItem('club.menus'),
            pageId: 0,
            pageTypeId: 0,
            pageName: ""
        }
    }





    componentDidMount() {
        if (!sHelpers.defaultInputValidation()) {
            setTimeout(() => {
                this.props.history.push('/pageerror');
            }, Config.minMsgTimeout);
            return false;
        }

        token = sHelpers.secureStorage.getItem('club.token');
        /// Get Content Data

        inputParam = QueryString.parse(window.location.search);
        // console.log(inputParam.pid);
        // console.log(inputParam.ptid);

        this.setState({ pageId: parseInt(inputParam.pid), pageTypeId: parseInt(inputParam.ptid) })

        if (inputParam.pid === undefined || inputParam.pid === "" || inputParam.pid === null) {
            this.props.history.push('/home');
            return false;
        }
        if (inputParam.ptid === undefined || inputParam.ptid === "" || inputParam.ptid === null) {
            this.props.history.push('/home');
            return false;
        }

        sHelpers.mTop();
        this.getPageDetails();
        // const script = document.createElement("script");
        // script.src = "../t1/assets/js/owl.carousel.min.js";
        // script.async = true;
        // document.body.appendChild(script);
        // setTimeout(() => {
        //     this.props.history.push('/register');
        // }, 1200);
    }



    getPageDetails() {

        this.setState({ loading: true });
        fetch(Config.baseClubUrl + 'GetClubPageDetail?PageId=' + inputParam.pid + '&PageType=' + inputParam.ptid, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                //console.log(response);
                if (response.errorCode === 0) {
                    //Get
                    this.setState({ sliderImages: response.pageDetail.imageList, pageHeading: response.pageDetail.pageTitle, pageDescription: response.pageDetail.preview, pageName: response.pageDetail.pageName });
                    // console.log(this.state.sliderImages);
                }
                else {
                    this.props.alert.error(response.message);
                }
                this.setState({ loading: false });
            })
            .catch(err => {
                this.setState({ loading: false });
                this.props.alert.error(err);
            });
    }

    render() {

        return (

            <>
                {/* <!-- /.top-bar --> */}
                <Header></Header>
                {/* <Slider  {...homeslidersettings} >
                    <img src="../t1/assets/images/slider/slide-1.png" alt="The Last of us" />
                    <img src="../t1/assets/images/slider/slide-1.png" alt="The Last of us" />
                </Slider> */}
                {/* <!-- /.navigation start--> */}
                {/* <!-- /.search start--> */}

                <div className="slide-container">
                    {this.state.sliderImages.length > 0 &&
                        <Zoom {...zoomInProperties}>
                            {this.state.sliderImages.map((each, index) => (
                                <div key={index} style={{ width: "100%" }}>
                                    <img style={{ objectFit: "cover", width: "100%" }} src={Config.imgBaseURL + each.pathName + each.imageName} alt="club-app-lucid" />
                                </div>
                            ))}
                        </Zoom>
                    }
                    {this.state.sliderImages.length === 0 &&
                        <Zoom {...zoomInProperties}>
                            {this.state.defaultsliderImages.map((each, index) => (
                                <div key={index} style={{ width: "100%" }}>
                                    <img style={{ objectFit: "cover", width: "100%" }} src={each.imageUrl} alt="club-app-lucid" />
                                </div>
                            ))}
                        </Zoom>
                    }
                </div>

                {/* <div>
                    <Slide easing="ease">
                        <div className="each-slide">
                            <div style={{ 'backgroundImage': `url(${slideImages[0]})` }}>
                                <span>Slide 1</span>
                            </div>
                        </div>
                        <div className="each-slide">
                            <div style={{ 'backgroundImage': `url(${slideImages[1]})` }}>
                                <span>Slide 2</span>
                            </div>
                        </div>
                        <div className="each-slide">
                            <div style={{ 'backgroundImage': `url(${slideImages[2]})` }}>
                                <span>Slide 3</span>
                            </div>
                        </div>
                    </Slide>
                </div> */}
                <div className="section-space20">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-md-12 col-md-12 col-sm-12 col-12">
                                <div className="mb60 text-center section-title">
                                    <h1>{this.state.pageName}</h1>
                                </div>
                                <div className="mb60 section-title">

                                    {/* <!-- section title start--> */}
                                    {/* <h2 className="mb30 text-left">{this.state.pageHeading}</h2> */}
                                    {this.state.pagePreview !== "" &&
                                        <p className="home-content" dangerouslySetInnerHTML={{ __html: this.state.pageDescription }}></p>
                                        // <p>The late Maharaja Nalvadi Krishnaraja Wadiyar,
                                        // with far-sightedness and his profound sense of scenic beauty,
                                        // chose the foothills of the famous chamundi hills as the locale
                                        //      for the race and golf courses. <strong>The JWGC is located on 110 acres</strong>
                                        //       in the premises of the mysore race club. On 1940 on his accession
                                        //       to the throne, his highness Jayachamaraja Wadiyar took a keen interest
                                        //        in racing and golf and maintained the golf course.
                                        // </p>
                                    }
                                </div>

                                {/* <!-- /.section title start--> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withAlert()(Home);