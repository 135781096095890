import React, { Component } from 'react';
import Header from '../../component/header';
import Config from '../../data/siteconfig';
import LoadingAnimation from '../../component/loadinganimation';
import './payment.scss'
export default class PaymentSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({ loading: true });
            setTimeout(() => {
                this.props.history.push('/dashboard');
            }, Config.averageMsgTimeout);
        }, Config.maxMsgTimeout);
    }
    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                <Header></Header>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="wrapper-content bg-white pinside60">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="error-ctn text-center">
                                            <h5 class="error-title mb40">Payment was completed successfully.<br /> Thank you!</h5>
                                            <a href="/dashboard" class="btn btn-secondary text-center">Go to Account</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}