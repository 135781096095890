import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Config from '../../data/siteconfig';
import SecureStorage from 'secure-web-storage';
let token = '';
let mobileNo = '';
let membershipNo = '';
let membershipId = '';
let loggedIn = false;
let memberName = '';
   let memberUserName = '';



var CryptoJS = require("crypto-js");
var SECRET_KEY = Config.secretkey;

class StaticHelpers extends Component {
    static defaultInitiate() {
        token = this.secureStorage.getItem('club.token');
    }
    static LoggedinInitiate() {
        mobileNo = this.secureStorage.getItem('club.mobile.no');
        membershipNo = this.secureStorage.getItem('club.membership.no');
        membershipId = this.secureStorage.getItem('club.membership.id');
        loggedIn = this.secureStorage.getItem('club.user.login');
        memberName = this.secureStorage.getItem('club.displayname');
        memberUserName = this.secureStorage.getItem('club.username');
    }

    static defaultInputValidation() {
        this.defaultInitiate();

        if (token === null || token === "") {
            return false;
        }
        return true;
    }

    static LoggedinInputValidation() {
        this.defaultInitiate();
        this.LoggedinInitiate();
        // console.log(mobileNo);
        // console.log(membershipNo);
        // console.log(membershipId);
        // console.log(loggedIn);

        if (mobileNo === null || mobileNo === "") {
            return false;
        }
        else if (membershipNo === null || membershipNo === "") {
            return false;
        }
        else if (membershipId === null || membershipId === 0) {
            return false;
        }
        else if (loggedIn === null || loggedIn !== true) {
            return false;
        }
        else if (memberName === null || memberName === "") {
            return false;
        }
        else if (memberUserName === null || memberUserName === "") {
            return false;
        }
        return true;
    }



    static removeStoredData() {
        this.secureStorage.removeItem('club.mobile.no');
        this.secureStorage.removeItem('club.membership.no');
        this.secureStorage.removeItem('club.membership.id');
        this.secureStorage.removeItem('club.user.login');
        this.secureStorage.removeItem('club.displayname');
        this.secureStorage.removeItem('club.username');
    }

    static secureStorage = new SecureStorage(localStorage, {
        hash: function hash(key) {
            key = CryptoJS.SHA256(key, SECRET_KEY);

            return key.toString();
        },
        encrypt: function encrypt(data) {
            data = CryptoJS.AES.encrypt(data, SECRET_KEY);

            data = data.toString();

            return data;
        },
        decrypt: function decrypt(data) {
            data = CryptoJS.AES.decrypt(data, SECRET_KEY);

            data = data.toString(CryptoJS.enc.Utf8);

            return data;
        }
    });

    // Move Top
    static mTop() {
        window.scrollTo(0, 0);
    }

    static getCurrentDateTime(Inptxt) {
        let currentdate = new Date();
        let hours = currentdate.getHours();
        let ampm = hours >= 12 ? 'PM' : 'AM';

        let datetime = Inptxt + currentdate.getDate() + "/"
            + (currentdate.getMonth() + 1) + "/"
            + currentdate.getFullYear() + " "
            + currentdate.getHours() + ":"
            + currentdate.getMinutes() + ":"
            + currentdate.getSeconds() + " "
            + ampm;

        return datetime;
    }
}
export default withRouter(StaticHelpers)