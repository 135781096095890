import React, { Component } from 'react';
import './pageerror.scss';
export default class Home extends Component {
    render() {
        return (
            <>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="wrapper-content bg-white pinside60">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" id="error-main">
                                        <div class="error-ctn text-center">
                                            <h3 className="error-icon"><i class="fa fa-exclamation-circle"></i></h3>
                                            <h3 class="error-title mb20">OOPS!</h3>
                                            <h3 class="error-description mb40">We're sorry. Something went wrong.</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}