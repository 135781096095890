const Config = {
    crossPlatform: {
        None: 0,
        Android: 1,
        Web: 2,
        IOS: 3
    },
    paymentStatus: {
        None: 0,
        Pending: 1,
        Success: 2,
        Failure: 3,
        Cancelled: 4
    }
}
module.exports = Config;